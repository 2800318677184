@import '~@auspost/postmaster-styles/index.scss';

.settlements {
    :global {
        a {
            cursor: pointer;
        }
        .btn-col {
            max-width: 140px;;
            button {
                min-width: 120px;
                max-width: 120px;
                min-height: 20px;
            }
        }
    }
}
