@import '~@auspost/postmaster-styles/index.scss';

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.air-datepicker-global-container .air-datepicker {
    --adp-cell-background-color-selected: #dc1928;
    --adp-day-name-color-hover: #b71521;
    --adp-accent-color: #b71521;
    --adp-cell-background-color-selected-hover: #b71521;
}

.air-datepicker-global-container .air-datepicker-body--day-names .air-datepicker-body--day-name {
    color: #dc1928;
    font-weight: bold;
    text-transform: initial;
}

.air-datepicker-global-container .air-datepicker-body--cells.-days- {
    .air-datepicker-cell.-selected- {
        font-weight: bold;
    }

    .air-datepicker-cell.-current- {
        color: #dc1928;
    }

    .air-datepicker-cell.-current-.-selected- {
        color: white;
    }
}

body {
    background-color: #F5F5F5;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.tablegrids {
    text-align: center;
}

.tablegrids table td:last-child {
    width: 30%;
}

.container {
    @include xl {
        max-width: 1200px;
        margin: 0 auto;
    }

    @include xxl {
        max-width: 1500px;
        margin: 0 auto;
    }
}

.container.main {
    padding-top: $BASE_MEASURE*2;

    h1 {
        margin-bottom: $BASE_MEASURE*2;
    }
}

.main {
    .ui.breadcrumb {
        line-height: 3;
        display: block;
        background-color: white;
        font-size: smaller;
        color: gray;
        a {
            color: gray;
            text-decoration: underline;
        }
    
    }
}

input[type=text].small {
    @include input-field-small;
}

@media (min-width: 680px){
    .table-container .header-container .header .actions {
        align-items: row;
    }

    .table-content .actions {
        button:not(:first-child) {
            margin-left: 16px;
        }
    }
}

.fields .actions {
    margin-top: 0.4em; 
    display: flex;
    direction: rtl;
    align-items: flex-end;
    button:not(:last-child) {
        margin-left: 16px;
    }
}

.table-container {
    @include table-container;
    box-shadow: $BOX_SHADOW_2;

    .header-container {
        @include table-header-container;

        .header {
            @include table-header;                        
            @include table-header-content;

            .actions {
                @include table-actions-stacked;
                @include table-actions-inline;
            }

            .actions {
                @include md;
                flex-direction: row;
                justify-content: end;
            }
        }
    }

    .table-content {
        .flex-container {
            width: 100%;
        }
        div.detail > .flex-container, .accordion .flex-container {
            margin-bottom: 1em;                
        }
    }

    .fields {
        label > span {
            margin-bottom: 1px;
        }
    }

    .row {
        @include table-row;

        td {
            flex: 1;
        }
    }

    tr.header.row {
        border-top: none;
        padding: 10px 40px 10px;

        >th {
            text-align: left;
            flex: 1;
            border-top: none;
            color: #6D6D72;
            text-transform: uppercase;
            font-size: smaller;
        }
    }

    .accordion {
        max-width: calc(100% + 4px);
    }
}

@media (min-width: 680px){
    .table-container .header-container div.header {
        padding-bottom: 1.2em;
    }
    .table-container div.table-content {
        padding-top: 0;
        .fields {
            border-top: none;
            padding-top: 0;
            padding-bottom: 0;            
        }
        .fields:last-child {
            padding-bottom: 1em;
        }
    }
}

.flex-container {
    @include flex-container;
}
.flex-container.actions {
    justify-content: end;
    margin-bottom: 2em;
}

.flex-row {
    @include flex-row;
}

.flex-cell-1 {
    @include flex-cell(1);
}

.flex-cell-2 {
    @include flex-cell(2);
}

.flex-cell-3 {
    @include flex-cell(3);
}

.flex-cell-4 {
    @include flex-cell(4);
}

.flex-cell-5, input[type=text].small.flex-cell-5 {
    @include flex-cell(5);
}

.flex-cell-6, input[type=text].small.flex-cell-6 {
    @include flex-cell(6);
}

.flex-cell-8 {
    @include flex-cell(8);
}

.flex-cell-10 {
    @include flex-cell(10);
}

.flex-cell-11 {
    @include flex-cell(11);
}

.flex-cell-12 {
    @include flex-cell(12);
}

.flex-cell-fill {
    flex-grow: 100;
}

.flex-cell-fill, input[type=text].small.flex-cell-fill {
    flex-grow: 100;
    width: auto;
}

.flex-cell-auto {
    width: auto;
    padding-right: 0.5em;
    padding-top: 8px;
}

.change-icon {
    text-align: right;
    padding-right: 0;
    padding-left: 0.5em;
    i {
        margin-right: 0;
    }
    .arrow.right {
        color: #dc1928;
    }
}   

.lds-ring {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
}

.lds-ring > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 6px;
    border: 6px solid #dc1928;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #dc1928 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}