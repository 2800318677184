@import '~@auspost/postmaster-styles/index.scss';


.productDetail {
    width: 100%;
    :global {
        .detail-info {
            color: blue;
            margin: 0.5em 0;
        }        

        i.asterisk.icon {
            color: rgb(183, 21, 33);
        }

        .accordion {
            margin-bottom: 1em;
            textarea {
                resize: vertical;
                height: 3em;
            }
            div[role=region] {
                .flex-container:last-child {
                    margin-bottom: 0;                    
                }
            }
        }                
    }
}
