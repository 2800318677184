@import '~@auspost/postmaster-styles/index.scss';

.cti {
    :global {       
        .center {
            text-align: center;            
        }
        input[type='checkbox'] { transform: scale(1.5); margin-left: 0.4em; }
        .flex-container > input[type='checkbox'] { transform: scale(1.5); margin: 0 8px; }
        select.track {
            min-width: 48%;
            max-width: 48%;
        }
    }
}