@import '~@auspost/postmaster-styles/index.scss';

.entitySelect {
    > div, > select {
        width: 100%;
    }
    :global {
        .lds-ring {
            width: 36px;
            height: 36px;
        }

        .lds-ring > div {
            width: 32px;
            height: 32px;
            margin: 5px;
        }
    }
}