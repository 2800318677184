@import '~@auspost/postmaster-styles/index.scss';


.productUpdate {
    :global {
        .detail-info {
            color: blue;
            margin: 0.5em 0;
        }

        .banner {
            margin-top: 0.6em;
            padding: 6px 16px;
        }        
    }
}
