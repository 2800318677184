@import '~@auspost/postmaster-styles/index.scss';


.denominationAdd {
    :global {
        .detail-info {
            color: blue;
            margin: 0.5em 0;
        }

        .banner {
            margin-top: 16px;
            padding: 6px 16px;
        }
        
        .table-content {
            .accordion {
                margin-bottom: 1em;
                textarea {
                    width: 100%;
                    resize: vertical;
                }
                div[role=region] {
                    .flex-container:last-child {
                        margin-bottom: 0;                    
                    }
                }
            }
        }    
        .table-content.fields > .flex-container {
            margin-bottom: 0;                
        }    
    }
}
