@import '~@auspost/postmaster-styles/index.scss';


.reviewStock {
    :global {
        .detail-info {
            color: blue;
            margin: 0.5em 0;
        }

        .modal-content {
            font-size: medium;
            line-height: 1.5;
        }

        .tablegrids table tr > td:first-child > div > span:first-child {
            display: none;
        }

        .tablegrids table td:last-child {
            width: 0;
        }

        .tablegrids table td:nth-child(4) {
            width: 12%;
        }
    }
}
