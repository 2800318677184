@import '~@auspost/postmaster-styles/index.scss';


.productAdd {
    :global {
        .detail-info {
            color: blue;
            margin: 0.5em 0;
        }
        
        .table-content {
            .accordion {
                margin-bottom: 1em;
                textarea {
                    width: 100%;
                    resize: vertical;
                }
                div[role=region] {
                    .flex-container:last-child {
                        margin-bottom: 0;                    
                    }
                }
            }
        }        
    }
}
