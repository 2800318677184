@import '~@auspost/postmaster-styles/index.scss';

.productAnalysisDetailed {
    :global {
        label {
            font-family: ap_text,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin: 0;
            line-height: 20px;
            letter-spacing: 0px;
            font-size: 14px;
            font-weight: 500;
            color: #212129;
        }
        button[role=tooltip] {
            position: relative;
            left: 2px;
            top: 3px;
        }
        h3{
            margin-bottom: 0.2em;
        }
        hr {
            margin-top: 1em;
        }
    }
}
