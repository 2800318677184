@import '~@auspost/postmaster-styles/index.scss';

.uploadBatch {
    :global {
        td > .lds-ring {
            zoom: 0.6;
            position: relative;
            top: 12px;
        }

        .dragdroparea > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            border-width: 2px;
            border-radius: 2px;
            border-color: #9d9d9d;
            border-style: dashed;
            background-color: #fafafa;
            color: #8d8d8d;
            outline: none;
            transition: border .24s ease-in-out
        }
    }
}
