@import '~@auspost/postmaster-styles/index.scss';

.navMenu {
    border-bottom: 1px solid lightgrey;

    :global {
        a .navbar-brand {
            white-space: normal;
            text-align: center;
            word-break: break-all;
        }      

        .ui.menu .item.active-menu {
            border-bottom: 3px solid gray;
            padding: 0.92857143em 1.14285714em calc(0.92857143em - 3px)
        }

        html {
            font-size: 14px;
        }

        @media (min-width: 768px) {
            html {
                font-size: 16px;
            }
        }

        .box-shadow {
            box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
        }

        .ui.menu {
            margin-top: 0;
            border: none;
            box-shadow: none;
            margin: 0 auto;

            .item:before {
                background: none;
            }
        }

        .header-divider {
            border-bottom: 1px solid lightgrey;
        }
        .header {
            @include flex-container;
            @include flex-row;
            padding-top: 8px;

            .brand {
                padding-right: 10px;
                vertical-align: middle;
                @include flex-cell(3);

                svg {
                    margin-right: 20px;
                }

                .name {
                    padding-left: 16px;
                    border-left: 1px solid grey;
                    position: relative;
                    top: -12px;
                    font-weight: bold;
                }

                @include xl {
                    position: relative;
                    left: -32px;
                }
            }

            .links {
                @include flex-cell(9);
                text-align: right;

                svg {
                    position: relative;
                    top: 2px;
                }

                span, a {
                    padding: 12px 24px;
                    display: inline-block;
                }

                .partner {
                    padding: 0;
                    color: inherit;
                    text-decoration: underline;
                    cursor: pointer;                    
                }
                .partner:hover {
                    color: #1e70bf;
                    text-decoration: none;
                }

                a:last-child {
                    padding-right: 0;
                }

                .icon {
                    position: relative;
                    top: 2px;
                }
            }
        }

        .container {
            @include xl {
                max-width: 1238px;
            }

            @include xxl {
                max-width: 1520px;
            }
        }
    }
}
