@import '~@auspost/postmaster-styles/index.scss';

.datePicker {
    :global {       

        .dp-fields {
            position: relative;

            svg {
                position: absolute;
                right: 8px;
                top: 8px;
                height: 22px;
                width: 22px;
                cursor: pointer;
            }
        }
    }
}