@import '~@auspost/postmaster-styles/index.scss';


.maintainBatch {
    :global {
        .detail-info {
            color: blue;
            margin: 0.5em 0;
        }

        .table-content button {
            min-width: auto;            
        }
        .table-content td {
            line-height: 44px;
        }

        @media (min-width: 680px) {
            .table-container.batches .table-content .row {
                padding: 1.2em 40px;
            }
        }

        .modal-content {
            font-size: medium;
            line-height: 1.5;
        }

        .tablegrids table tr > td:first-child > div > span:first-child {
            display: none;
        }

        .tablegrids table td:last-child {
            width: 0;
        }

        .tablegrids table td:nth-child(4) {
            width: 12%;
        }
    }
}
